import { WhmStack, WhmTypography } from "@securitize/reactjs-whm";
import Empty from "../../../assets/Empty.svg";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import React from "react";
import { useNavigate } from "react-router";
import { VERIFICATION_URL } from "../routing-constants";
import { WhmCustomButton } from "../../Shared/WhmCustomButton";

interface VerificationNeededProps {
  currentPath: string;
}

export const VerificationNeeded: React.FC<VerificationNeededProps> = ({
  currentPath,
}) => {
  const navigate = useNavigate();

  return (
    <WhmStack sx={{ padding: "24px", alignItems: "center" }}>
      <img src={Empty} alt="profile" style={{ height: "124px" }} />
      <WhmTypography
        variant={"h4"}
        sx={{ marginTop: "16px", textAlign: "center" }}
      >
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_TITLE
          }
        />
      </WhmTypography>
      <WhmTypography
        variant={"body1"}
        sx={{ textAlign: "center", marginTop: "8px" }}
      >
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_SUBTITLE
          }
        />
      </WhmTypography>
      <WhmCustomButton
        onClick={() => navigate(VERIFICATION_URL(currentPath))}
        variant="contained"
        color="primary"
        data-test-id="profile-personal-information-empty-cta"
        sx={{ marginTop: "16px" }}
      >
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_CTA
          }
        />
      </WhmCustomButton>
    </WhmStack>
  );
};
