import { WhmBox, whmTheme, WhmTypography } from "@securitize/reactjs-whm";
import { InfoCard } from "../shared/InfoCard";
import React, { ReactNode } from "react";
import { WhmCustomButton } from "../../Shared/WhmCustomButton";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../Translation/Translate";

interface SettingsSecurityCardProps {
  title: ReactNode;
  subtitle: ReactNode;
  onMfa: () => void;
  onChangePassword: () => void;
  hasMfa: boolean;
}

export const SettingsSecurityCard: React.FC<
  React.PropsWithChildren<SettingsSecurityCardProps>
> = ({ title, subtitle, onMfa, hasMfa, onChangePassword }) => {
  return (
    <InfoCard title={title} subtitle={subtitle} smallPadding>
      <WhmBox
        sx={{
          marginTop: "16px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <WhmBox
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: { xs: "column", xl: "row" },
            padding: { xs: "8px 0 16px 0", xl: "4px 0 16px 0" },
          }}
        >
          <WhmBox
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "4px",
              padding: "4px 8px 4px 0",
            }}
          >
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.primary}
            >
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_TITLE
                }
              />
            </WhmTypography>
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.secondary}
            >
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_SUBTITLE
                }
              />
            </WhmTypography>
          </WhmBox>
          <WhmCustomButton
            variant={hasMfa ? "outlined" : "contained"}
            color="primary"
            onClick={onMfa}
            data-test-id="profile-settings-2fa-cta"
            sx={{
              minWidth: "114px",
              alignSelf: { xs: "flex-start", xl: "center" },
              marginTop: { xs: "8px", xl: "0" },
              marginBottom: { xs: "8px", xl: "0" },
            }}
          >
            {hasMfa ? (
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_DISABLE_CTA
                }
              />
            ) : (
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_2FA_CTA
                }
              />
            )}
          </WhmCustomButton>
        </WhmBox>

        <WhmBox
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: { xs: "column", xl: "row" },
            borderTop: "1px solid",
            borderColor: whmTheme.palette.divider,
            paddingTop: "16px",
          }}
        >
          <WhmBox
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "4px",
              padding: "4px 8px 4px 0",
            }}
          >
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.primary}
            >
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_TITLE
                }
              />
            </WhmTypography>
            <WhmTypography
              variant="body2"
              color={whmTheme.palette.text.secondary}
            >
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_SUBTITLE
                }
              />
            </WhmTypography>
          </WhmBox>
          <WhmCustomButton
            variant={"outlined"}
            color="primary"
            onClick={onChangePassword}
            data-test-id="profile-settings-2fa-cta"
            sx={{
              minWidth: "154px",
              alignSelf: { xs: "flex-start", xl: "center" },
              marginTop: { xs: "8px", xl: "0" },
            }}
          >
            <Translate
              translationKey={
                TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_CTA
              }
            />
          </WhmCustomButton>
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
