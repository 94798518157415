import React from "react";
import { WhmBox, WhmSkeleton } from "@securitize/reactjs-whm";
import { SettingsTopLoadingCard } from "./SettingsTopLoadingCard";
import { SettingsSecurityLoadingCard } from "./SettingsSecurityLoadingCard";
import { SettingsDevicesLoadingCard } from "./SettingsDevicesLoadingCard";
import { SettingsSmallLoadingCard } from "./SettingsSmallLoadingCard";
import { SettingsLanguageLoadingCard } from "./SettingsLanguageLoadingCard";

export const SettingsLoadingPage: React.FC = () => (
  <>
    <WhmSkeleton
      data-test-id="profile-investment-profile-top-skeleton"
      sx={{ marginTop: "16px" }}
      variant="rounded"
      width={"100%"}
      height={52}
    ></WhmSkeleton>
    <WhmBox
      sx={{
        paddingTop: "20px",
        flexDirection: { xs: "column", xl: "row" },
        display: "flex",
        gap: "20px",
      }}
    >
      <SettingsTopLoadingCard />
      <SettingsTopLoadingCard />
    </WhmBox>

    <WhmBox
      sx={{
        paddingTop: "20px",
        flexDirection: { xs: "column", xl: "row" },
        display: "flex",
        gap: "20px",
      }}
    >
      <SettingsSecurityLoadingCard />
      <SettingsDevicesLoadingCard />
    </WhmBox>

    <WhmBox
      sx={{
        paddingTop: "20px",
        flexDirection: { xs: "column", xl: "row" },
        display: "flex",
        gap: "20px",
      }}
    >
      <WhmBox
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column" },
          gap: "20px",
          order: { xs: 1, xl: 0 },
        }}
      >
        <SettingsSmallLoadingCard />
        <SettingsSmallLoadingCard />
      </WhmBox>

      <SettingsLanguageLoadingCard />
    </WhmBox>
  </>
);
