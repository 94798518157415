import React from "react";
import {
  WhmBox,
  WhmInputAdornment,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import { SettingsLoadingPage } from "./loading/SettingsLoadingPage";
import { SettingsFieldCard } from "./SettingsFieldCard";
import { SettingsSecurityCard } from "./SettingsSecurityCard";
import { ReactComponent as EmailIcon } from "@securitize/assets-sec-ui/dist/icons/svg/email.svg";
import { useGetSettingsQuery } from "../../../data/useProfile";

export interface SettingsProps {
  isLoading: boolean;
  hasMfa: boolean;
  onMfa: () => void;
  onChangePassword: () => void;
}

export const Settings = ({
  isLoading,
  hasMfa,
  onMfa,
  onChangePassword,
}: SettingsProps) => {
  const settingsQuery = useGetSettingsQuery();

  // Main UI
  if (settingsQuery.isPending || isLoading) {
    return <SettingsLoadingPage />;
  }

  if (settingsQuery.data) {
    return (
      <>
        <WhmStack sx={{ padding: "16px 0px" }}>
          <WhmTypography variant="h4">
            <Translate
              translationKey={TranslationKeys.PROFILE_SETTINGS_TITLE}
            />
          </WhmTypography>
          <WhmTypography variant="body2" color="GrayText">
            <Translate
              translationKey={TranslationKeys.PROFILE_SETTINGS_SUBTITLE}
            />
          </WhmTypography>

          <WhmBox
            sx={{
              flexDirection: { xs: "column", xl: "row" },
              display: "flex",
              alignItems: "baseline",
              gap: { xs: "0", xl: "20px" },
            }}
          >
            <SettingsFieldCard
              title={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_EMAIL_CARD_TITLE
                  }
                />
              }
              subtitle={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_EMAIL_CARD_SUBTITLE
                  }
                />
              }
              footer={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_EMAIL_CARD_FOOTER
                  }
                />
              }
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_EMAIL_CARD_FIELD_LABEL
                  }
                />
              }
              value={settingsQuery.data.email}
              dataId={"profile-settings-email-field"}
              startAdornment={
                <WhmInputAdornment position="start">
                  <EmailIcon
                    width={24}
                    height={24}
                    fill={whmTheme.palette.action.disabled}
                  />
                </WhmInputAdornment>
              }
            />
            <SettingsFieldCard
              title={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_TITLE
                  }
                />
              }
              subtitle={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_SUBTITLE
                  }
                />
              }
              footer={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_FOOTER
                  }
                />
              }
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_SETTINGS_PHONE_NUMBER_CARD_FIELD_LABEL
                  }
                />
              }
              value={settingsQuery.data.phone?.number}
              dataId={"profile-settings-phone-number-field"}
              startAdornment={
                settingsQuery.data.phone?.code ? (
                  <WhmInputAdornment
                    position="start"
                    sx={{
                      lineHeight: "normal",
                      ".MuiTypography-root": {
                        color: whmTheme.palette.text.disabled,
                      },
                    }}
                  >
                    {settingsQuery.data.phone.code.startsWith("+")
                      ? settingsQuery.data.phone.code
                      : `+${settingsQuery.data.phone.code}`}
                  </WhmInputAdornment>
                ) : null
              }
            />
          </WhmBox>
          <WhmBox
            sx={{
              flexDirection: { xs: "column", xl: "row" },
              display: "flex",
              alignItems: "baseline",
              gap: { xs: "0", xl: "20px" },
            }}
          >
            <WhmBox
              sx={{
                flexBasis: "50%",
              }}
            >
              <SettingsSecurityCard
                title={
                  <Translate
                    translationKey={
                      TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_TITLE
                    }
                  />
                }
                subtitle={
                  <Translate
                    translationKey={
                      TranslationKeys.PROFILE_SETTINGS_SECURITY_CARD_SUBTITLE
                    }
                  />
                }
                onMfa={onMfa}
                hasMfa={hasMfa}
                onChangePassword={onChangePassword}
              />
            </WhmBox>
            <WhmBox
              sx={{
                flexBasis: "50%",
              }}
            />
          </WhmBox>
        </WhmStack>
      </>
    );
  } else {
    // TODO - Replace no data scenario with error screen in a future task - ON-939
    return <WhmBox>No data</WhmBox>;
  }
};
