import { createContext } from "react";

export interface ProfileContextType {
  hasMfa: boolean;
  onMfa: () => void;
  onChangePassword: () => void;
}

export const ProfileContext = createContext({} as ProfileContextType);

type Props = {
  hasMfa: boolean;
  onMfa: () => void;
  onChangePassword: () => void;
  children: React.ReactNode;
};

const ProfileProvider: React.FC<Props> = ({
  hasMfa,
  onMfa,
  onChangePassword,
  children,
}) => {
  return (
    <ProfileContext.Provider value={{ hasMfa, onMfa, onChangePassword }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
