import config from "./config";
import Cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import { InvestorDto } from "./dtos/investor.dto";
import { InvestmentProfileDto } from "./dtos/investment-profile.dto";
import { ProfileStatusDto } from "./dtos/profile-status.dto";
import { SettingsDto } from "./dtos/settings.dto";

export const useGetInvestorInformationQuery = () =>
  useQuery({
    queryKey: ["investor-information"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/personal-information`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching investor information");
      }

      return response.json() as Promise<InvestorDto>;
    },
  });

export const useGetInvestmentProfileQuery = () =>
  useQuery({
    queryKey: ["investment-profile"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/investment-profile`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching investment profile");
      }

      return response.json() as Promise<InvestmentProfileDto>;
    },
  });

export const useGetSettingsQuery = () =>
  useQuery({
    queryKey: ["settings"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/settings`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching settings");
      }

      return response.json() as Promise<SettingsDto>;
    },
  });

export const useGetPendingActionsQuery = () =>
  useQuery({
    queryKey: ["pending-actions"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/profile/pending-actions`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching investment profile");
      }

      return response.json() as Promise<ProfileStatusDto>;
    },
  });
