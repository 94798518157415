import React from "react";
import {
  WhmBox,
  WhmCard,
  WhmCardContent,
  WhmSkeleton,
} from "@securitize/reactjs-whm";

export const SettingsSecurityLoadingCard: React.FC = () => (
  <WhmCard
    variant={"outlined"}
    data-test-id="profile-settings-top-skeleton-card"
    sx={{
      display: "flex",
      width: "100%",
    }}
  >
    <WhmCardContent
      sx={{
        width: "100%",
        padding: "16px",
      }}
    >
      <WhmBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "24px",
        }}
      >
        <WhmSkeleton variant="rounded" width={120} height={20}></WhmSkeleton>
        <WhmSkeleton variant="rounded" width={"100%"} height={20}></WhmSkeleton>
      </WhmBox>

      <WhmBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "4px 16px 4px 0",
          marginBottom: "8px",
        }}
      >
        <WhmSkeleton variant="rounded" width={120} height={20}></WhmSkeleton>
        <WhmSkeleton variant="rounded" width={"100%"} height={40}></WhmSkeleton>
      </WhmBox>

      <WhmBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "4px 16px 4px 0",
        }}
      >
        <WhmSkeleton variant="rounded" width={120} height={20}></WhmSkeleton>
        <WhmSkeleton variant="rounded" width={"100%"} height={20}></WhmSkeleton>
      </WhmBox>
    </WhmCardContent>
  </WhmCard>
);
