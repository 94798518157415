import React from "react";
import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";
import { WhmCustomButton } from "../../../Shared/WhmCustomButton";
import { ACCREDITATION_URL } from "../../routing-constants";
import { useNavigate } from "react-router";
import {
  AccreditationStatus,
  AccreditationStatuses,
  VerificationStatus,
  VerificationStatuses,
} from "../../../../data/types/investors";

interface AccreditationButtonProps {
  accreditationStatus: AccreditationStatus;
  verificationStatus: VerificationStatus;
  currentPath: string;
}

export const AccreditationButton: React.FC<AccreditationButtonProps> = ({
  currentPath,
  accreditationStatus,
  verificationStatus,
}) => {
  const navigate = useNavigate();

  return (
    <WhmCustomButton
      onClick={() => navigate(ACCREDITATION_URL(currentPath))}
      variant={
        accreditationStatus === AccreditationStatuses.NONE
          ? "contained"
          : "outlined"
      }
      sx={{ marginTop: "8px" }}
      disabled={verificationStatus === VerificationStatuses.NONE}
      color="primary"
      data-test-id="profile-complete-accreditation-cta"
    >
      {accreditationStatus !== AccreditationStatuses.NONE ? (
        <Translate
          translationKey={
            TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_CTA_VIEW
          }
        />
      ) : (
        <Translate
          translationKey={
            TranslationKeys.PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_CTA_COMPLETE
          }
        />
      )}
    </WhmCustomButton>
  );
};
