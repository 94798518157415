import React, { useContext, useEffect } from "react";
import {
  WhmAvatar,
  WhmGrid,
  WhmSkeleton,
  WhmStack,
  WhmTab,
  WhmTabs,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { PersonalInformation } from "./personal-information/PersonalInformation";
import { ReactComponent as PersonIcon } from "@securitize/assets-sec-ui/dist/icons/svg/individual-profile.svg";
import { ReactComponent as PersonalInformationIcon } from "@securitize/assets-sec-ui/dist/icons/svg/entity-info.svg";
import { ReactComponent as TaxIcon } from "@securitize/assets-sec-ui/dist/icons/svg/tax.svg";
import { ReactComponent as InvestmentProfileIcon } from "@securitize/assets-sec-ui/dist/icons/svg/acreditation.svg";
import { ReactComponent as SettingsIcon } from "@securitize/assets-sec-ui/dist/icons/svg/settings.svg";
import { Translate } from "../Translation/Translate";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import useUserInfo from "../../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router";
import {
  profilePaths,
  tabRoutes,
  tabRoutesToProfilePaths,
} from "./routing-constants";
import { InvestmentProfile } from "./investment-profile/InvestmentProfile";
import { useGetPendingActionsQuery } from "../../data/useProfile";
import TabIcon from "./shared/TabIcon";
import { Settings } from "./settings/Settings";
import { ProfileContext } from "../../contexts/ProfileContext";

const ALLOWED_TAB_PATHS = Object.values(tabRoutes);

export const Profile = () => {
  const userInfo = useUserInfo();
  const { onMfa, hasMfa, onChangePassword } = useContext(ProfileContext);

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState(
    tabRoutes.personalInformation,
  );

  const pendingActionsQuery = useGetPendingActionsQuery();

  // Tab navigation
  const { tab } = useParams();

  // Open the correct tab on load based on path
  useEffect(() => {
    if (tab && ALLOWED_TAB_PATHS.includes(tab)) {
      setSelectedTab(tab);
    } else {
      navigate(profilePaths.personalInformation, { replace: true });
    }
  }, [tab, navigate]);

  // Handle tab clicks
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (ALLOWED_TAB_PATHS.includes(newValue)) {
      navigate(tabRoutesToProfilePaths[newValue]);
    }
  };

  const userName =
    (userInfo.investorType === "individual"
      ? userInfo.fullName
      : userInfo.entityName) || userInfo.investorUserEmail;

  return (
    <WhmStack sx={{ padding: "0" }}>
      <WhmTypography variant="h1">
        <Translate translationKey={TranslationKeys.PROFILE_TITLE} />
      </WhmTypography>

      <WhmGrid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ marginTop: "16px" }}
      >
        <WhmAvatar
          sx={{
            bgcolor: whmTheme.palette.grey["300"],
            width: "32px",
            height: "32px",
          }}
        >
          <PersonIcon width={24} height={24} />
        </WhmAvatar>
        <WhmTypography
          variant="h5"
          data-test-id="profile-user-name"
          sx={{ marginLeft: "8px" }}
        >
          {userName}
        </WhmTypography>
      </WhmGrid>

      {pendingActionsQuery.isLoading ? (
        <div
          id="tabs-skeleton"
          data-test-id="profile-tabs-skeleton"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
            marginTop: "16px",
          }}
        >
          <WhmSkeleton variant="rounded" width={210} height={50}></WhmSkeleton>
          <WhmSkeleton variant="rounded" width={141} height={50}></WhmSkeleton>
          <WhmSkeleton variant="rounded" width={191} height={50}></WhmSkeleton>
          <WhmSkeleton variant="rounded" width={125} height={50}></WhmSkeleton>
        </div>
      ) : (
        <WhmTabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="personal-information-tabs"
          sx={{
            marginTop: "16px",
            display: "flex",
          }}
        >
          <WhmTab
            icon={
              <TabIcon
                icon={
                  <PersonalInformationIcon
                    width={24}
                    height={24}
                    fill="currentColor"
                  />
                }
                hasBadge={
                  pendingActionsQuery.data?.profile.personalInformation
                    .pendingAction
                }
                badgeTestId="profile-personal-information-badge"
              />
            }
            data-test-id="profile-personal-information-tab"
            iconPosition="start"
            value={tabRoutes.personalInformation}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAB_TITLE
                }
              />
            }
            sx={{ textTransform: "none", minHeight: "48px", gap: "6px" }}
          />
          <WhmTab
            // TODO add badge with logic when pending actions for tax center are implemented.
            icon={<TaxIcon width={24} height={24} fill="currentColor" />}
            iconPosition="start"
            data-test-id="profile-tax-center-tab"
            value={tabRoutes.taxCenter}
            label={
              <Translate
                translationKey={TranslationKeys.PROFILE_TAX_CENTER_TAB_TITLE}
              />
            }
            sx={{ textTransform: "none", minHeight: "48px", gap: "6px" }}
          />
          <WhmTab
            icon={
              <TabIcon
                icon={
                  <InvestmentProfileIcon
                    width={24}
                    height={24}
                    fill="currentColor"
                  />
                }
                hasBadge={
                  pendingActionsQuery.data?.profile.investmentProfile
                    .pendingAction
                }
                badgeTestId="profile-investment-profile-badge"
              />
            }
            iconPosition="start"
            data-test-id="profile-investment-profile-tab"
            value={tabRoutes.investmentProfile}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_INVESTMENT_PROFILE_TAB_TITLE
                }
              />
            }
            sx={{ textTransform: "none", minHeight: "48px", gap: "6px" }}
          />
          <WhmTab
            icon={<SettingsIcon width={24} height={24} fill="currentColor" />}
            iconPosition="start"
            data-test-id="profile-settings-tab"
            value={tabRoutes.settings}
            label={
              <Translate
                translationKey={TranslationKeys.PROFILE_SETTINGS_TAB_TITLE}
              />
            }
            sx={{ textTransform: "none", minHeight: "48px", gap: "6px" }}
          />
        </WhmTabs>
      )}

      {selectedTab === tabRoutes.personalInformation && (
        <PersonalInformation isLoading={pendingActionsQuery.isLoading} />
      )}

      {selectedTab === tabRoutes.taxCenter && (
        <WhmTypography variant="h2">Tax Center Content</WhmTypography>
      )}

      {selectedTab === tabRoutes.investmentProfile && (
        <InvestmentProfile isLoading={pendingActionsQuery.isLoading} />
      )}

      {selectedTab === tabRoutes.settings && (
        <Settings
          isLoading={pendingActionsQuery.isLoading}
          onMfa={onMfa}
          hasMfa={hasMfa}
          onChangePassword={onChangePassword}
        />
      )}
    </WhmStack>
  );
};
