import React from "react";
import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../../Translation/Translate";
import { WhmCustomButton } from "../../../Shared/WhmCustomButton";
import { INVESTMENT_PROFILE_URL } from "../../routing-constants";
import { useNavigate } from "react-router";

interface SuitabilityButtonProps {
  currentPath: string;
}

export const SuitabilityButton: React.FC<SuitabilityButtonProps> = ({
  currentPath,
}) => {
  const navigate = useNavigate();

  return (
    <WhmCustomButton
      onClick={() => navigate(INVESTMENT_PROFILE_URL(currentPath))}
      variant="contained"
      color="primary"
      data-test-id="profile-complete-investment-profile-cta"
      sx={{
        marginTop: "8px",
      }}
    >
      <Translate
        translationKey={TranslationKeys.PROFILE_INVESTMENT_PROFILE_CARD_CTA}
      />
    </WhmCustomButton>
  );
};
