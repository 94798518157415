const translationKeys = {
  REGISTRATION_NEXT: "Texts.registration-next",
  REGISTRATION_ACCOUNT_EXISTS: "Texts.registration-account-exists",
  REGISTRATION_LOGIN: "Texts.registration-login",

  REGISTRATION_STEP_NAME_TITLE: "Texts.registration-step-name-title",
  REGISTRATION_STEP_NAME_SUBTITLE: "Texts.registration-step-name-subtitle",
  REGISTRATION_STEP_PHONE_TITLE: "Texts.registration-step-phone-title",
  REGISTRATION_STEP_PHONE_SUBTITLE: "Texts.registration-step-phone-subtitle",
  REGISTRATION_STEP_TYPE_TITLE: "Texts.registration-step-type-title",
  REGISTRATION_STEP_ENTITY_TITLE: "Texts.registration-step-entity-title",

  REGISTRATION_FIRST_NAME: "Texts.registration-first-name",
  REGISTRATION_LAST_NAME: "Texts.registration-last-name",

  REGISTRATION_RESIDENCE_COUNTRY: "Texts.registration-residence-country",
  REGISTRATION_RESIDENCE_STATE: "Texts.registration-residence-state",
  REGISTRATION_PHONE: "Texts.registration-phone",

  INDIVIDUAL: "Texts.individual",
  ENTITY: "Texts.entity",
  INDIVIDUAL_DESCRIPTION: "Texts.individual-description",
  ENTITY_DESCRIPTION: "Texts.entity-description",

  REGISTRATION_ENTITY_NAME: "Texts.registration-entity-name",
  REGISTRATION_ENTITY_COUNTRY: "Texts.registration-entity-country",
  REGISTRATION_ENTITY_STATE: "Texts.registration-entity-state",

  REGISTRATION_VALIDATION_REQUIRED_FIRST_NAME:
    "Texts.registration-validation-required-first-name",
  REGISTRATION_VALIDATION_INVALID_NAME:
    "Texts.registration-validation-invalid-name",
  REGISTRATION_VALIDATION_REQUIRED_LAST_NAME:
    "Texts.registration-validation-required-last-name",
  REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_COUNTRY:
    "Texts.registration-validation-required-residence-country",
  REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_STATE:
    "Texts.registration-validation-required-residence-state",
  REGISTRATION_VALIDATION_REQUIRED_PHONE:
    "Texts.registration-validation-required-phone",
  REGISTRATION_VALIDATION_INVALID_PHONE:
    "Texts.registration-validation-invalid-phone",
  REGISTRATION_VALIDATION_REQUIRED_ACCOUNT_TYPE:
    "Texts.registration-validation-required-account-type",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_NAME:
    "Texts.registration-validation-required-entity-name",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_COUNTRY:
    "Texts.registration-validation-required-entity-country",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_STATE:
    "Texts.registration-validation-required-entity-state",
  REGISTRATION_VALIDATION_MINIMUM_LENGTH:
    "Texts.registration-validation-minimum-length",
  REGISTRATION_VALIDATION_MAXIMUM_LENGTH:
    "Texts.registration-validation-maximum-length",

  // Phone Verification Modal
  PHONE_VERIFICATION_TITLE: "Texts.phone-verification-modal-title",
  PHONE_VERIFICATION_PHONE_STEP_SUBTITLE:
    "Texts.phone-verification-modal-phone-step-subtitle",
  PHONE_VERIFICATION_PHONE_STEP_FOOTER_CONSENT:
    "Texts.phone-verification-modal-phone-step-footer-consent",
  PHONE_VERIFICATION_PHONE_STEP_FOOTER:
    "Texts.phone-verification-modal-phone-step-footer",
  PHONE_VERIFICATION_PHONE_STEP_CTA_BUTTON:
    "Texts.phone-verification-modal-phone-step-cta-button",
  PHONE_VERIFICATION_CODE_STEP_SUBTITLE:
    "Texts.phone-verification-modal-code-step-subtitle",
  PHONE_VERIFICATION_CODE_STEP_FOOTER:
    "Texts.phone-verification-modal-code-step-footer",
  PHONE_VERIFICATION_CODE_STEP_CTA_BUTTON:
    "Texts.phone-verification-modal-code-step-cta-button",
  PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST:
    "Texts.phone-verification-modal-code-step-new-code-request",
  PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST_BUTTON:
    "Texts.phone-verification-modal-code-step-new-code-request-button",
  PHONE_VERIFICATION_CODE_STEP_CODE_RESENT:
    "Texts.phone-verification-modal-code-step-new-code-resent",
  PHONE_VERIFICATION_PHONE_INPUT_LABEL:
    "Texts.phone-verification-modal-phone-input-label",
  PHONE_VERIFICATION_PHONE_INPUT_EMPTY_HELPER_TEXT:
    "Texts.phone-verification-modal-phone-input-empty-helper-text",
  PHONE_VERIFICATION_PHONE_INPUT_INVALID_HELPER_TEXT:
    "Texts.phone-verification-modal-phone-input-invalid-helper-text",
  PHONE_VERIFICATION_CODE_INPUT_EMPTY_HELPER_TEXT:
    "Texts.phone-verification-modal-code-input-empty-helper-text",
  PHONE_VERIFICATION_CODE_INPUT_INVALID_HELPER_TEXT:
    "Texts.phone-verification-modal-code-input-invalid-helper-text",
  PHONE_VERIFICATION_NUMBER_VERIFIED:
    "Texts.phone-verification-modal-number-verified",
  PHONE_VERIFICATION_ERROR_GENERATING_CODE:
    "Texts.phone-verification-modal-error-generating-code",

  // Snackbar
  SNACKBAR_GENERAL_ERROR_BUTTON_TEXT:
    "Texts.snackbar-general-error-button-text",

  // User Menu
  USER_MENU_INVESTOR_STATUS_VERIFIED:
    "Texts.user-menu-investor-status-verified",
  USER_MENU_INVESTOR_STATUS_PENDING: "Texts.user-menu-investor-status-pending",
  USER_MENU_INVESTOR_STATUS_DOCUMENTS_EXPIRED:
    "Texts.user-menu-investor-status-documents-expired",
  USER_MENU_INVESTOR_STATUS_BLOCKED: "Texts.user-menu-investor-status-blocked",
  USER_MENU_INVESTOR_STATUS_UPDATES_REQUIRED:
    "Texts.user-menu-investor-status-updates-required",
  USER_MENU_INVESTOR_STATUS_NOT_VERIFIED:
    "Texts.user-menu-investor-status-not-verified",
  USER_MENU_INVESTOR_STATUS_EXPIRED: "Texts.user-menu-investor-status-expired",
  USER_MENU_INVESTOR_STATUS_SERVICE_DENIED:
    "Texts.user-menu-investor-status-service-denied",

  USER_MENU_CREATE_ENTITY_ACCOUNT: "Texts.user-menu-create-entity-account",

  USER_MENU_ERROR_LOADING_INVESTORS: "Texts.user-menu-error-loading-investors",
  USER_MENU_LOADING_INVESTORS: "Texts.user-menu-loading-investors",

  // Profile
  PROFILE_TITLE: "Texts.profile-title",
  PROFILE_PERSONAL_INFORMATION_TAB_TITLE:
    "Texts.profile-personal-information-tab-title",
  PROFILE_TAX_CENTER_TAB_TITLE: "Texts.profile-tax-center-tab-title",
  PROFILE_INVESTMENT_PROFILE_TAB_TITLE:
    "Texts.profile-investment-profile-tab-title",
  PROFILE_SETTINGS_TAB_TITLE: "Texts.profile-settings-tab-title",

  // Personal Information
  PROFILE_PERSONAL_INFORMATION_TITLE:
    "Texts.profile-personal-information-title",
  PROFILE_PERSONAL_INFORMATION_SUBTITLE:
    "Texts.profile-personal-information-subtitle",

  PROFILE_PERSONAL_INFORMATION_EMPTY_TITLE:
    "Texts.profile-personal-information-empty-title",
  PROFILE_PERSONAL_INFORMATION_EMPTY_SUBTITLE:
    "Texts.profile-personal-information-empty-subtitle",
  PROFILE_PERSONAL_INFORMATION_EMPTY_CTA:
    "Texts.profile-personal-information-empty-cta",

  PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_TITLE:
    "Texts.profile-personal-information-personal-details-card-title",
  PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_SUBTITLE:
    "Texts.profile-personal-information-personal-details-card-subtitle",
  PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_FOOTER:
    "Texts.profile-personal-information-personal-details-card-footer",
  PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_TITLE:
    "Texts.profile-personal-information-address-card-title",
  PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_SUBTITLE:
    "Texts.profile-personal-information-address-card-subtitle",
  PROFILE_PERSONAL_INFORMATION_ADDRESS_CARD_FOOTER:
    "Texts.profile-personal-information-address-card-footer",
  PROFILE_PERSONAL_INFORMATION_TAX_CARD_TITLE:
    "Texts.profile-personal-information-tax-card-title",
  PROFILE_PERSONAL_INFORMATION_TAX_CARD_SUBTITLE:
    "Texts.profile-personal-information-tax-card-subtitle",
  PROFILE_PERSONAL_INFORMATION_TAX_CARD_FOOTER:
    "Texts.profile-personal-information-tax-card-footer",
  PROFILE_PERSONAL_INFORMATION_TAX_CARD_TOOLTIP:
    "Texts.profile-personal-information-tax-card-tooltip",

  PROFILE_PERSONAL_INFORMATION_FIELDS_FIRST_NAME:
    "Texts.profile-personal-information-fields-first-name",
  PROFILE_PERSONAL_INFORMATION_FIELDS_MIDDLE_NAME:
    "Texts.profile-personal-information-fields-middle-name",
  PROFILE_PERSONAL_INFORMATION_FIELDS_LAST_NAME:
    "Texts.profile-personal-information-fields-last-name",
  PROFILE_PERSONAL_INFORMATION_FIELDS_BIRTHDATE:
    "Texts.profile-personal-information-fields-birthdate",
  PROFILE_PERSONAL_INFORMATION_FIELDS_ADDRESS:
    "Texts.profile-personal-information-fields-address",
  PROFILE_PERSONAL_INFORMATION_FIELDS_ADDITIONAL_INFO:
    "Texts.profile-personal-information-fields-additional-info",
  PROFILE_PERSONAL_INFORMATION_FIELDS_CITY:
    "Texts.profile-personal-information-fields-city",
  PROFILE_PERSONAL_INFORMATION_FIELDS_POSTAL_CODE:
    "Texts.profile-personal-information-fields-postal-code",
  PROFILE_PERSONAL_INFORMATION_FIELDS_COUNTRY:
    "Texts.profile-personal-information-fields-country",
  PROFILE_PERSONAL_INFORMATION_FIELDS_STATE:
    "Texts.profile-personal-information-fields-state",
  PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_RESIDENCE:
    "Texts.profile-personal-information-fields-tax-residence",
  PROFILE_PERSONAL_INFORMATION_FIELDS_TAX_ID:
    "Texts.profile-personal-information-fields-tax-id",

  // Investment Profile
  PROFILE_INVESTMENT_PROFILE_TITLE: "Texts.profile-investment-profile-title",
  PROFILE_INVESTMENT_PROFILE_SUBTITLE:
    "Texts.profile-investment-profile-subtitle",
  PROFILE_INVESTMENT_PROFILE_CARD_TITLE:
    "Texts.profile-investment-profile-card-title",
  PROFILE_INVESTMENT_PROFILE_CARD_SUBTITLE:
    "Texts.profile-investment-profile-card-subtitle",
  PROFILE_INVESTMENT_PROFILE_CARD_CTA:
    "Texts.profile-investment-profile-card-cta",
  INVESTMENT_PROFILE_STATUS_IN_REVIEW:
    "Texts.investment-profile-status-in-review",
  INVESTMENT_PROFILE_STATUS_COMPLETED:
    "Texts.investment-profile-status-completed",
  INVESTMENT_PROFILE_STATUS_REJECTED:
    "Texts.investment-profile-status-rejected",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_TITLE:
    "Texts.profile-investment-profile-accreditation-card-title",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_SUBTITLE:
    "Texts.profile-investment-profile-accreditation-card-subtitle",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_ALERT_MESSAGE:
    "Texts.profile-investment-profile-accreditation-card-alert-message",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_ALERT_CTA:
    "Texts.profile-investment-profile-accreditation-card-alert-cta",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_CTA_COMPLETE:
    "Texts.profile-investment-profile-accreditation-card-cta-complete",
  PROFILE_INVESTMENT_PROFILE_ACCREDITATION_CARD_CTA_VIEW:
    "Texts.profile-investment-profile-accreditation-card-cta-view",

  // Settings
  PROFILE_SETTINGS_TITLE: "Texts.profile-settings-title",
  PROFILE_SETTINGS_SUBTITLE: "Texts.profile-settings-subtitle",
  PROFILE_SETTINGS_EMAIL_CARD_TITLE: "Texts.profile-settings-email-card-title",
  PROFILE_SETTINGS_EMAIL_CARD_SUBTITLE:
    "Texts.profile-settings-email-card-subtitle",
  PROFILE_SETTINGS_EMAIL_CARD_FOOTER:
    "Texts.profile-settings-email-card-footer",
  PROFILE_SETTINGS_EMAIL_CARD_FIELD_LABEL:
    "Texts.profile-settings-email-card-field-label",
  PROFILE_SETTINGS_PHONE_NUMBER_CARD_TITLE:
    "Texts.profile-settings-phone-number-card-title",
  PROFILE_SETTINGS_PHONE_NUMBER_CARD_SUBTITLE:
    "Texts.profile-settings-phone-number-card-subtitle",
  PROFILE_SETTINGS_PHONE_NUMBER_CARD_FOOTER:
    "Texts.profile-settings-phone-number-card-footer",
  PROFILE_SETTINGS_PHONE_NUMBER_CARD_FIELD_LABEL:
    "Texts.profile-settings-phone-number-card-field-label",
  PROFILE_SETTINGS_SECURITY_CARD_TITLE:
    "Texts.profile-settings-security-card-title",
  PROFILE_SETTINGS_SECURITY_CARD_SUBTITLE:
    "Texts.profile-settings-security-card-subtitle",
  PROFILE_SETTINGS_SECURITY_CARD_FOOTER:
    "Texts.profile-settings-security-card-footer",
  PROFILE_SETTINGS_SECURITY_CARD_2FA_TITLE:
    "Texts.profile-settings-security-card-2fa-title",
  PROFILE_SETTINGS_SECURITY_CARD_2FA_SUBTITLE:
    "Texts.profile-settings-security-card-2fa-subtitle",
  PROFILE_SETTINGS_SECURITY_CARD_2FA_CTA:
    "Texts.profile-settings-security-card-2fa-cta",
  PROFILE_SETTINGS_SECURITY_CARD_2FA_DISABLE_CTA:
    "Texts.profile-settings-security-card-2fa-disable-cta",
  PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_TITLE:
    "Texts.profile-settings-security-card-password-title",
  PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_SUBTITLE:
    "Texts.profile-settings-security-card-password-subtitle",
  PROFILE_SETTINGS_SECURITY_CARD_PASSWORD_CTA:
    "Texts.profile-settings-security-card-password-cta",

  LOGOUT: "Texts.logout",
} as const;

export default translationKeys;

export type TranslationKey =
  (typeof translationKeys)[keyof typeof translationKeys];
